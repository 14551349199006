<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Autoplay, Navigation, Controller, Pagination } from "swiper";
import { nextTick } from "vue";
import "swiper/swiper-bundle.min.css";
import Nav from "@/components/Nav/index.vue";
export default {
    name: "Missions",
    data() {
        return {};
    },
    components: {
        Nav,
        Swiper,
        SwiperSlide,
        Pagination,
    },
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
    },
    watch: {
        userNbInfo: function () {
            let vm = this;
            if (!vm.userNbInfo.data.user.isBind) {
                vm.$router.push("/welcome");
            }
        },
    },
    setup() {
        const controlledSwiper = ref(null);
        const setControlledSwiper = (swiper) => {
            controlledSwiper.value = swiper;
        };

        return {
            modules: [Autoplay, Navigation, Controller, Pagination],
            controlledSwiper,
            setControlledSwiper,
        };
    },
    mounted() {
        nextTick(() => {
            let vm = this;
            if (vm.userNbInfo.data) {
                if (!vm.userNbInfo.data.user.isBind) {
                    vm.$router.push("/welcome");
                }
            }
        });
    },
    methods: {
        sendGtm(str) {
            window.dataLayer.push({
                event: "horyuken_event",
                event_name: str,
                event_time: new Date().toLocaleString(),
            });
        },
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
<style>
/* swiper 樣式要用css寫法才能覆蓋 */
.swiper-pagination {
    transform: translateY(-0.9em);
}
.swiper-pagination-bullet {
    display: inline-block;
    background: transparent;
    border: 1px solid #737b82;
    font-size: 20px;
    width: 0.4em;
    height: 0.4em;
    opacity: 1;
    box-sizing: border;
}
.swiper-pagination-bullet-active {
    background: #cf0a2c;
    border-color: #cf0a2c;
}
</style>
